import React, { useState, useRef, useEffect } from 'react';
import '../styles/Chatbot.css';
import logo_nematool from '../assets/logo.png';
import Papa from 'papaparse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassPlus, faMagnifyingGlassMinus, faDownload, faArrowLeft, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';

const Chatbot = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [fitosanitariosData, setFitosanitariosData] = useState([]);
  const [data, setData] = useState([]);
  const [productoSeleccionado, setProductoSeleccionado] = useState(null);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const fetchFitosanitariosData = async (nombreComercial) => {
    try {
      const cache = await caches.open('fitosanitarios-cache');
      const cachedResponse = await cache.match('fitosanitarios.csv');
      
      if (cachedResponse) {
        const csv = await cachedResponse.text();
        const parsedData = Papa.parse(csv, { header: true }).data;
        setData(parsedData);
        console.log(parsedData);
        const productosFiltrados = parsedData.filter(row => {
          if (row['Nombre_Comercial']) {
            return row['Nombre_Comercial'].toLowerCase().includes(nombreComercial.toLowerCase());
          }
          return false;
        });
        console.log(productosFiltrados);
        setProductoSeleccionado(productosFiltrados);
        return productosFiltrados;
      } else {
        console.error('No se encontró el archivo CSV en la caché');
        return [];
      }
    } catch (error) {
      console.error('Error al obtener datos de fitosanitarios:', error);
      return [];
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      const trimmedMessage = message.trim();
      setMessage('');

      if (trimmedMessage === "borrar") {
        setChatHistory([]);
      } else {
        setChatHistory(prevHistory => [...prevHistory, { text: trimmedMessage, sender: 'user' }]);
        await fetchChatGPTResponse(trimmedMessage);
      }
    }
  };

  const handleButtonClick = async () => {
    const trimmedMessage = message.trim();
    setMessage('');

    if (trimmedMessage === "borrar") {
      setChatHistory([]);
    } else {
      setChatHistory(prevHistory => [...prevHistory, { text: trimmedMessage, sender: 'user' }]);
      await fetchChatGPTResponse(trimmedMessage);
    }
  };

  const fetchChatGPTResponse = async (prompt) => {
    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer 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'
        },
        body: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: [
            { role: "system", content: "Extrae el nombre comercial del producto fitosanitario mencionado en la pregunta del usuario. Responde solo con el nombre comercial, sin texto adicional." },
            { role: "user", content: prompt }
          ]
        })
      });

      if (!response.ok) {
        throw new Error('Error en la respuesta de la API');
      }

      const data = await response.json();
      const nombreComercial = data.choices[0].message.content.trim();

      const productosInfo = await fetchFitosanitariosData(nombreComercial);

      let botResponse = "";
      if (productosInfo && productosInfo.length > 0) {
        const primerProducto = productosInfo[0];
        const enfermedadesPorCultivo = {};

        // Agrupar enfermedades por cultivo
        productosInfo.forEach(producto => {
          const cultivo = producto.Cultivo;
          const agente = producto.Agente;
          const dosisMin = producto.Dosis_Min;
          const dosisMax = producto.Dosis_Max;
          const unidadMedida = producto['Unidad Medida dosis'];

          if (!enfermedadesPorCultivo[cultivo]) {
            enfermedadesPorCultivo[cultivo] = { agentes: [], dosisMin, dosisMax, unidadMedida };
          }
          enfermedadesPorCultivo[cultivo].agentes.push(agente);
        });

        botResponse = `<strong>${nombreComercial}</strong>:<br><br>` +
                      `<strong>Número de registro:</strong> ${primerProducto.Num_Registro}<br><br>` +
                      `<strong>Formulado:</strong> ${primerProducto.Formulado}<br><br>` +
                      `<strong>Titular:</strong> ${primerProducto.Titular}<br><br>` +
                      `<span><strong>Cultivos y Agentes:</strong></span><br><br>`;

        for (const [cultivo, info] of Object.entries(enfermedadesPorCultivo)) {
          botResponse += `- <strong>${cultivo} (${info.dosisMin} - ${info.dosisMax} ${info.unidadMedida})</strong>:<br><ul>`;
          info.agentes.forEach(agente => {
            botResponse += `<li>${agente}</li>`;
          });
          botResponse += `</ul>`;
        }
      } else {
        botResponse = `Introduce un nombre comercial válido.`;
      }

      setChatHistory(prevHistory => [...prevHistory, { text: botResponse, sender: 'bot', isHtml: true }]);
    } catch (error) {
      console.error('Error al obtener respuesta de ChatGPT:', error);
      setChatHistory(prevHistory => [...prevHistory, { text: "Lo siento, ha ocurrido un error. Por favor, intenta de nuevo.", sender: 'bot' }]);
    }
  };

  return (
    <div>
      <div className="header-div">
        <button className="back-button" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <img src={logo_nematool} alt="Logo" className="header-logo" />
        <h1 id="chatbot-title">Fitosanitarios</h1>
      </div>
      <div id="chat-wrapper">
        <div id="chat-container" ref={chatContainerRef}>
          {chatHistory.map((chat, index) => (
            <div key={index} className={`chat-bubble mensaje ${chat.sender}`}>
              {chat.isHtml ? (
                <span dangerouslySetInnerHTML={{ __html: chat.text }}></span>
              ) : (
                chat.text.split('\n').map((line, i) => (
                  <span key={i}>{line}<br /></span>
                ))
              )}
            </div>
          ))}
        </div>
        <div id="input-wrapper" className="input-container">
          <textarea 
            id="chat-input" 
            placeholder="Escribe el nombre comercial" 
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            rows="1"
          />
          <button 
            onClick={handleButtonClick} 
            className="input-button" 
            disabled={!message.trim()}
          >
            <FontAwesomeIcon icon={faArrowUp} className="arrow-icon" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;